@import "sass-lib";
@media (min-width: 0px) and (max-width: 767px) {
	.wrapper{
	 	width: auto; margin: 0 30px; 
	 	&.v2{ width: auto; margin: 0 30px; }
	}
	.btn{
		&.gradient{ 
			line-height: 40px; font-size: 14px;
			span{ width:40px; background-size: 100%; }
		}
	}
	header{
		height: 55px;
		&:before{ height:55px; width: 35%; }
		&.no-login {
			&:before {width: calc(100% - (100% - 255px));}
		}
		&:before{ height:55px; width: calc(100% - (100% - 345px)); }
		.wrapper{ margin: 0px 30px 0px 20px; }
		.logo{ width: 90px; }
		.btn.export{ display: block; line-height: 36px; height: 36px; }
		.right-header{ 
			padding-top: 10px; 
			.burgermenu{
				font-size: 7px; text-align: center; margin-top: 5px;
				.togglemenu{ width: 30px; height: 20px; }
			}
		}
		.btn.login{ margin: 0px 5px 0px 0px; }
		.nav-burgermenu{
			left: auto; width: 100%;
			.in-nav-burgermenu{ 
				width: 100%; 
				.menu{
					margin-bottom: 25px;
					> li{
						 font-size:18px; padding: 5px 30px;  
						&:hover{
							&:before{ top:0; bottom: auto; height: 35px;}
						}
					}
					.hv_child {
						> a {
							display: block; padding-right: 30px; position: relative;
							&:after {
								content:"";width: 12px;height: 8px;background: url(../images/material/arr-wht-dwn.png) no-repeat center; display: block;
								position: absolute; top: 0; bottom: 0; margin: auto; left: auto; right: 10px; @include transition(all ease-out 0.25s);
							}
							&.act {
								&:after {@include transform(rotate(180deg)); @include transition(all ease-out 0.25s);}
							}
						}
					}
					.drop-burger {
						.hv_child {
							> a {
								margin-right: -20px;
								&.act-sub {
									&:after {@include transform(rotate(180deg)); @include transition(all ease-out 0.25s);}
								}
							}
						}
					}
				}
				&.corporate{ 
					width: 100%; 
					.menu{
						position: relative; z-index: 1;
						> li{
							.drop-burger{ 
								right: auto; width: 100%; background: transparent; position: relative;
								z-index: 2; top: auto; bottom: auto; opacity: 1; visibility: visible;
								display: none;
								>ul{ 
									height: auto; top: auto; left: auto; right: auto; bottom: auto;
									padding: 15px 0px 0;
									li{ padding: 0px 20px; }
								}
							 }
						}
					}
				}
			}
			.bottom-head{ padding: 0px 30px; }
			.roundedlink{
				flex-wrap: wrap;
				.tosite{ margin-right: 10px; margin-bottom: 10px; }
			}
			.close-nav{ right: 30px; }

		}
	}
	.list-job {
		.item {
			width: 100%; margin-bottom: 15px;
		}
	}
	.slider-home{
		figure{
			height: 100%;
			img{ 
				position: relative;
				width: auto;
				min-width: 100%;
				min-height: 100%;
				max-width: none;
				transform: translateY(-50%) translateX(-50%);
				top:50%;
				left:50%; 
			}
		}
	}
	.milestone-slider{
		margin: 0 -40px;
		.list{
			width: 200px; margin: 40px 0px; width: 155px;
			&:before{ left:0px; }
			.desc{
				h5{ font-size: 22px; }
			}
			figure{
				.shadow{ left: 13px; right: 16px; top: 13px; bottom: 17px;  }
			}
		}

	}

	.login-home{
		.text{
			width: auto; left: 20px; right: 20px; top: 35%; bottom: auto;
			h2{ font-size: 22px;line-height: 32px; }
			p{ display: none; }
		}
		.slider-login{
			.slick-dots{ bottom: auto; top: 52%; @include transform(translateY(-50%)); }
		}
		.form-login{ 
			width: 100%; right: 0; left: 0; bottom: 0; padding:60px 20px 20px;
			p{ margin: 5px 0px 5px; line-height: 22px; }
			a{ padding-bottom: 5px; font-size: 14px; }
		}
		.tittle-form{
			&.top{ 
				width: 100%; line-height: 35px; 
				span{
					&:before{ background-size:100%; width: 20px; top: -5px;}
				}
			}
		}
		.form{
			label{ font-size: 12px; margin-bottom: 5px; margin-left: 5px; }
			.col{ margin-bottom: 10px; }
			.row{
				&:last-child{ margin-top:10px; }
			}
		}
	}
	.banner-home{
		overflow:hidden; height: auto;
		&.consumer{ height: auto; }
		&.corpo{ 
			 height: 100vh; min-height: 400px;
		}
		.slider-home{
			height: 100%;
			figure{ 
				 
				img{
					position: relative;
				width: auto;
				min-width: 100%;
				min-height: 100%;
				max-width: none;
				transform: translateY(-50%) translateX(-50%);
				top:50%;
				left:50%;
				}
			}
			.slick-list,.slick-track,.list-slider{ height: 100%; }
		}
		.text{ 
			width: 60%; left: 10%; right: 10%;  
			h2{ font-size: 20px; line-height: 30px; }
			small{ 
				font-size: 12px; line-height: 22px; padding-left: 50px;
				&:before{ width:40px; }
			}
			p{ display: none; }
		}
		.tabing{
			right: 0; left: 10%;
			ol{
				li{
					width: 160px;
					a{ font-size: 12px; }
				}
			}
		}
	}
	.home{
		&.calculator{
			height: auto; padding: 22px 0px;
			&:before{ display:none; }
			&:after{ width: 100%; }
			.wrapper{ margin: 0px 40px; display: block; }
			.calculator-left,.calculator-right{ width: 100%; display: block; }
			.calculator-left{ 
				padding: 0px; 
				&:after{ display:none; }
			}
			.calculator-right{ 
				padding: 0; text-align: center; padding-top: 20px; margin: 0px auto; 
				a{ margin: 0 auto; }
			}
			.form-calculator{
				.bottom-form-calculator{
					height: auto; background: #fff; padding:10px 20px 40px; @include borderRadius(20px);
					> div{ width: 100%; display: block; border-bottom:1px solid #ddd; }
					input[type='text'],select{ width: 100%; @include boxSizing(border-box); background-size: 5%;}
				}
			}
			 h4{ display: none; }

			.bottom-form-calculator{
			 	button{ 
			 		top: auto; bottom: 5px; left: 20px; right: 20px; width: calc(100% - 40px); @include boxSizing(border-box); 
			 	}
			 	input[type='text'], input[type=number],select{
			 		width: 100%;
			 	}
			}
		}
		&.aboutus{
			.wrap-left-right{
				display: block;
				.left,.right{ display: block; width: 100%; }
				.right{ padding-left: 0px; padding-top: 15px; }
			}
		}
		
		.top-form-calculator{ display: none; }
	}
	.bottom-form-calculator{ 
		background: transparent; 
		input[type='text'], input[type=number], select{ width: 100%; display: block; }
		>div{
			&:after{ display:none; }
		}
	}
	.divider.abs{ display: none; }
	.partner{
		.tab-partner{
			.btn-tab-partner{ float: none; display: block; }
		}
		.sliderpartner{ float: none; width: 100%; margin-top: 20px; }
	}
	.product{
		.ttl-filter{ 
			display: block; 
			.lf,.rg{ float: none; display: block; width: 100%; }
			.left,.right{ float: none; display: block; width: 100%; }
			.left{
				margin-bottom: 15px;
			}
			.lf{ margin-bottom: 20px; }
		}
	}
	.wrap-product{
		.ls-product{ 
			width: 100%; float: none; display: block; margin: 0px 0px 30px 0px; 
			&:nth-child(4n){ margin-right: 0; }
		}
	}
	.calculator-foot{
		.lf-calculator{
			padding-right: 0;
			.btn-simulasi{ display: block; width: 100%; @include boxSizing(border-box); padding-right: 15px; }
			p{ display: none; }
		}
		&.float{ padding: 10px 0px; }
		.btn{ 
			position: relative; display: block; margin: 0 auto; width: auto; text-align: center; margin-top: 5px;
			height: 35px; line-height: 35px;
		}
		.form-calculator{
			.bottom-form-calculator{
				button{ bottom: 10px; top: auto; left: 0; right: 0; margin: 0 auto; }
			}
		}
	}
	.banner-middle{
		figure{ 
			height: 260px; 
			&:before{ height:100px; background-size: cover; background-position: top;}
		}
	}
	.middle{
		.wrapper{ width: auto; margin: 0 40px; }
		aside{
			top: -100px;
			.tittle{ 
				padding: 15px 15px 15px 0px; font-size: 16px; height: 50px;
				&:before{ width:60px; }
				.sub-trgr{ 
					line-height: 62px; font-size: 0; width: 12px; padding-right: 0;
					right: 0;  @include boxSizing(border-box); width: 100%;
					&:after{ right:10px; }
				}
			}
			ul{
				li{
					a{ font-size: 14px; line-height: 24px; }
				}
			}
			.navigasi{ padding: 70px 0 50px 30px; }
		}
		&.nobanner{ padding: 100px 0px 60px; }
	}
	.tittle-middle{
		h5{ font-size: 14px; line-height: 24px; }
		&:after{ width: 40px; left: -15px; }
	}
	.twobox-middle{
		.box{ float: none; display: block; width: 100%; margin-right: 0; margin-bottom: 20px; }
	}
	.wrap-product{
		&.three{
			.ls-product{ width: 100%; float: none; display: block; margin: 0 0 40px;}
		}
	}
	.std-content{
		ul{
			&.half{
				li{ width: 100%; float: none; display: block;  }
			}
		}
	}
	.img-middle{
		@include borderRadius(10px);
		.desc{ display: none; }
		.rounded-desc{
			h5{ font-size: 14px; }
		}
		&.detail{
			.rounded-desc{
				left: 20px; right: auto; width: 150px; bottom: 10px;
				h5{ margin-bottom: 0; line-height: 24px; }
				&:before{ width:480px; height: 340px; }
			}
		}
		&.big{
			.desc{
				display: block; left: 0px; right: 10px; padding: 10px 15px;
				p{ display: none; }
			}
		}
	}
	.visimisi{
		&.block{
			.text{
				&.visi{
					p{ font-size: 16px; line-height: 26px; }
				}
			}
		}
	}
	.twobox-bg{
		.list-box{ display: block; }
		figure,.text{ display: block; width: 100%; }
		figure{ height: 234px; width: 180px; margin: 0 auto; text-align: center; margin-bottom: 20px; }
		.text{ padding: 0px; }
	}
	.promo-list{
		figure,.text{ width: 100%; float: none; display: block; }
		.list{ height: auto; }
	}
	.detail-page{
		.det-left,.det-right{ width: 100%; float: none; display: block; height: auto;	}
	}
	.feature{
		.list-feat{ width: 50%; padding: 0 10px; }
	}
	.calldealer{
		display: block; padding: 20px;
		.left,.right{ display: block; width: 100%; float: none; }
	}
	.wrap-pop{
		.pop-inner{
			.box-popup{
				.list-popup{  
					display: block; 
					figure,.text-pop{ display: block; width: 100%;  }
					.text-pop{ padding-left: 0; padding-top: 20px; }
				}
			}
			.close{
			 	top: -35px; width: 25px; height: 25px; 
			 	&:after,&:before{ width:20px; height: 3px; }
			}
		}
		.mCSB_inside{
			> .mCSB_container{
				margin-right:0;
				padding-right: 15px;
			}
		}
		.mCSB_scrollTools{ right: -7px; }
	}
	.award{
		margin: 0px;
		.list-award{ 
			width: 100%; display: block;padding: 0px; 
			figure{ width: 182px; margin: 0 auto; }
			.text{ width: 182px; margin: 20px auto 0px; }
		}
	}
	.sec-form{
		label{ font-size: 12px;  }
		.row{
			.col{
				float: none;
				&.half{ width: 100%; margin-right: 0; display: block; margin: 0px 0px 30px; }
			}
		}
		.colopt{
			h5{ line-height: 24px; }
			.option{ width: 100%; display: block; margin-bottom: 10px; }
		}
		.content-form{ padding: 40px 20px 35px; }
	}
	.g-recaptcha{
		iframe{
			@include transform(scale(0.7));
			transform-origin: 0 0;
		}
	}
	.twobox-middle{
		.box{
			.rounded-desc{
				.text-hover{
					p{ max-height: 150px; }
				}
			}
			&:hover{
				.rounded-desc{
					.text-hover{ height:150px; }
				}
			}
		}
	}
	.table-middle{
		&.corpo{ 
			width: 100%; overflow-x:auto; 
			table{ width: 1000px; }
		}
	}
	.wrap-address{
		.addres{ float: none; display: block; width: 100%; }
		.ic{  }
		.wrap-ic{ display: block; }
	}
	.wrap-acc-middle{
		.content-acc{ padding:20px 5px 10px; }
	}
	.form-calculator{
		&.fixed{
			.bottom-form-calculator{
				height: auto; padding: 0px 10px 50px; background: #fff;
				> div{ width: 100%; display: block; }
				input[type='text']{ width: 100%; @include boxSizing(border-box);}

			}
		}
	}
	.table-middle{
		overflow-x: auto;
		table{
			width: 700px;
		}
		thead{
			th{ font-size: 15px; padding: 15px 20px; }
		}
		tbody{
			td{ font-size: 14px; padding: 10px 20px; }
		}
	}
	.tittle-form{ right: 0; @include borderRadius(40px 0px 0px 0px); }
	.tittle-contactus{
		padding:0px; left:20px; right: 20px; margin: 0 auto; 
		top: 70px;
		a{ display: inline-block; font-size: 16px; margin-left: -20px;}
		small{ font-size: 14px; line-height: 20px; }
	}
	.sec-form.nobanner{
		background: #1150A7; border-radius: 20px; overflow: hidden;

		.tittle-form{
			position: relative; top: auto; left: auto; right: auto; left: auto;
			width: 85%; @include borderRadius(10px 0px 50px 0px);
		}
		.content-form{
			padding-top: 40px;
		}
		&.bigpadding{
			.content-form{ padding-top: 0px; }
			.tittle-contactus{
				position: relative; left: auto; right: auto; top: auto;
				padding: 20px 0px;
			}
		}
		
	}
	.result-simulasi{
		table{
			thead{
				th{}
			}
			tbody{
				td{ padding: 20px 10px 0px; font-size: 12px; line-height: 22px; }
				.result{
					td{
						&:first-child{ font-size:12px; }
						&:last-child{ font-size: 14px; }
					}
				}
				i{ font-size:12px; line-height: 22px;  }
			}
			tr{
				&:last-child{
					td{ padding:20px 10px 20px; }
				}
			}
		}
		.row-btn{
			display: -webkit-box;
			display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column;
			.left,.right{ float: none; width: 100%; display: block;  }
			.right{ 
				-webkit-box-ordinal-group:2; 
				        order:1; display: -webkit-box; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column;
				a{ 
					display: block;  text-align: center; width: 200px; margin: 5px auto;
					&:first-child{ -webkit-box-ordinal-group:3; order:2; }
					&:last-child{ margin-right:auto; width:250px; -webkit-box-ordinal-group: 2; order: 1; }
				}
			}
			.left{ -webkit-box-ordinal-group:3; order:2; text-align: center; margin-top: 40px; }
		}
	}
	.sec-form.padbot200{ padding-bottom: 50px; }
	.twobox-list{
		.list{ 
			display: block;
			figure,.text{ width: 100%; display: block; }
			.text{ padding: 25px 0px; }
		}
		
	}
	.detail-corpo, .detail-corpo.nopadbot{ padding:0px; }
	.related-news{
		margin: 40px 0px 0px;
		.list-news{ 
			width: 100%; display: block; margin: 0px 0px 0; padding: 0;
			&:nth-child(2){ display: none; }

		}
	}
	.wrap-acc-middle{

		.grid-acc{
			padding: 20px;
			.loc{ display: block; margin-left: 0; }
			.plus{ right: 15px; }
		}
	}
	.pagging{
		a{ margin: 0px 5px; }
		.arr{ width: 10px; background-size: 100%; }
	}

	.search-result{
		h5{ font-size: 18px; line-height: 28px; }
		p{ font-size: 14px; line-height: 24px; }
		.bold{ font-size: 14px; line-height: 24px; }
		.list{ margin-bottom: 40px; }
		.tittle-result{
			margin-bottom: 30px;
			h3{ font-size: 22px; line-height: 32px; }
		}
	}
	.page100vh{
		.wrap{
			.text{
				left: 5%; right: 5%;
				h2{ font-size: 80px; margin-bottom: 20px; }
				h5{ font-size: 22px; line-height: 30px; margin-bottom: 20px; }
				p{ font-size: 14px; line-height: 24px; }
				&.under{
					h2{ font-size: 60px; }
				}
			}
		}
	}
	.pdf-sect{
		.row-filter{ display: none; }
		.wrap-pdf{
			.col{ 
				display: block; width: 100%; float: none;  margin-right: 0 !important; padding-bottom: 30px;
				.text{ padding-left: 20px; }
				small {left: 82px; bottom: 12px;}
			}

		}
	}
	.milestone-slider{
		&:before{ bottom:auto; top: 55%; }
		.list{ 
			padding: 60px 0px 0px; 
			&.slick-current{
				figure{ @include transform(scale(1.35)); }
			}
			.desc{ top: 20px; }
		}
	}
	.detail-milestone{
		margin: 0px -20px; display: block;
		.list{
			.inner{ padding:15px 20px;  }
		}
		p{ font-size: 14px; line-height: 24px; }
	}
	.std-content{
		ul li { padding-left: 20px; }
	}
	.product{
		padding: 50px 0px 0px; 
	}
	.tittle-form.number a{ 
		font-size: 16px; padding-left: 20px;
		&:before{ 
			background-size:100%; width: 13px;
		}
	}
	footer{
		.foot-top{
			.foot-top-left,.foot-top-right{ float: none; display: block; width: 100%; }
			.foot-top-left{ 
				margin-bottom: 25px; 
				.box-foot{ float: none; }
			}
			.form-newsletter{ width: 100%; }
		}
		.foot-bottom{
			.wrapper{ display: -webkit-box; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; flex-direction: column;}
			.foot-bottom-left{ -webkit-box-ordinal-group: 3; order: 2; margin-bottom: 0; line-height: 22px;  }
			.foot-bottom-right{ -webkit-box-ordinal-group:2; order:1; margin-bottom: 20px;}
		}
	}
}


@media (min-width: 0px) and (max-width: 479px) {
    header {
    	&:before {width: calc(100% - (100% - 200px));}
    	&.no-login {
    		&:before {width: calc(100% - (100% - 150px));}
    	}
        .btn.login {font-size: 0; width: 0;}
        .btn.export {
            padding: 0; width: 40px; font-size: 0;
            &:before {left: 32%;}
        }
    }
    .wrap-pop#award .pop-inner .content-pop {width: auto; margin: 15% 30px; padding: 20px;}
}

@media (min-width: 0px) and (max-width: 400px) {
.g-option {
    .option {margin-right:0px; display: block;}
}
}